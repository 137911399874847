import React from 'react';
import Container from "../Container";
import Row from "../Row";
import EntryTitle from "../../components/EntryTitle";
import {sectionTitle} from "./constants";
import SectionContent from "../../components/SectionContent";
import SectionFooter from "../../components/SectionFooter";
import SectionInner from "../../components/SectionInner";
import GetEarlyAccessButton from "../../components/GetEarlyAccessButton";

export const IntroduceSection = () => {
  return (
    <section className="introduce-section">
      <Container>
        <Row>
          <SectionInner prefix={"introduce-section"}>
            <EntryTitle title={sectionTitle} classes={"col-7"}/>
            <SectionContent classes={"col-5"}>
              {/* <p>Transforming NFTs into a liquidity vehicle for Pre-IDO tokens and a whole new suite of DeFi products
                across multiple blockchains.</p> */}
                <p>Crafting a whole new suite of NFT powered products across multiple blockchains</p>
              {/* <GetEarlyAccessButton/> */}
            </SectionContent>
            {/* <SectionFooter>
              <p>Powered by <img src="/img/polkadot.svg" alt="Polkadot"/></p>
            </SectionFooter> */}
          </SectionInner>
          <div className={"images-background"}>
            <img className={'layer-0'} src={"/img/genesis-rotate.svg"}/>
            <img className={'layer-1'} src={"/img/logo-rotate.svg"}/>
          </div>
        </Row>
      </Container>
    </section>
  );
};
