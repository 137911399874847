import React from 'react';
import MobileNavigation from "./MobileNavigation";
import NavigationItems from "./NavigationItems";
import DesktopNavigation from "./DesktopNavigation";

export const Navigation = ({isMobile = false}) => {

  if (isMobile) {
    return <MobileNavigation>
      <NavigationItems mobile={isMobile}/>
    </MobileNavigation>
  }

  return (
    <DesktopNavigation>
      <NavigationItems/>
    </DesktopNavigation>
  );
};