import React from 'react';

export const MobileNavigation = ({children}) => {
    return (
        <div className="mobile-nav_wrap">
            <div className="mobile-nav">
                {children}
            </div>
        </div>
    );
};
