import React from "react";
import Header from "./common/Header";
import MainSection from "./common/MainSection";
import IntroduceSection from "./common/IntroduceSection";
import RethinkingNftsSection from "./common/RethinkingNftsSection";
import CompareTokensSection from "./common/CompateTokensSection";
import PiecesSections from "./common/PiecesSections";
import GenShardsSection from "./common/GenShardsSection";
import ServiceSection from "./common/ServiceSection";
import InvestorPartnerSection from "./common/InvestorPartnerSection";
import TeamSection from "./common/TeamSection";
import ContactSection from "./common/ContactSection";
import Footer from "./common/Footer";
import Contact from "./common/Contact/Contact";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
require('dotenv').config()

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <MainSection>
              <IntroduceSection />
              {/* hiding section for now */}
              {/* <RethinkingNftsSection />
              <CompareTokensSection />
              <PiecesSections />
              <GenShardsSection />
              <ServiceSection />
              <InvestorPartnerSection />
              <TeamSection />
              <ContactSection /> */}
            </MainSection>
          </>
        }>
        </Route>
        <Route path="/contact" element={<Contact />}></Route>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
