import React, { useState } from 'react';
import { Container } from "../Container/Container";
import { Row } from "../Row/Row";
import ChainPartnerImg1 from "../../images/chain-partner-img-1.svg";
import ChainPartnerImg2 from "../../images/chain-partner-img-2.svg";
import InvestPartnerImg from "../../images/invester-partner.svg";
import UiuxIcon from "../../images/uiuxicon.svg";
import TimeIcon from "../../images/timer.svg";
import EsyImplinemtetion from "../../images/hand-lamp.svg";
import toast from 'react-toast-notification';

import axios from 'axios';
import '../Contact/Contact.sass'
import { Link } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_API_BASE_URL
const addUserApiUrl = `${BASE_URL}/api/v1/genshards/contacts`

export default function Contact() {
  const [file, setFile] = useState();
  console.log(file)
  const [userRegister, setUserRegister] = useState({
    name: "",
    email: "",
    telegramId: "",
    twitter: "",
    projectOrAdvising: "",
    industry: "",
    blockChain: "",
    launchUrgency: "",
    tentativeLaunchDate: "",
    otherNotes: "",
    chainSupport: "",
    topProjects: "",
    interestedInPartner: "",
  });
  const [dataShow, setDataShow] = useState({
    project: false,
    advisingProject: false,
  })
  const handleChange = (event) => {
    if(event.target.name) {
      setUserRegister({
        ...userRegister,
        [event.target.name]: event.target.value
        
      });
    }
    if(event.target.files) {
      setFile(event.target.files[0]);
    }
  }

  const handleSubmit = (event) => {

    event.preventDefault();

    var checkErrorFlag = false
    if (userRegister.name.length <= 5) {
      checkErrorFlag = true
      toast.error("Name must greter then 5 charachter");
    }
    else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(userRegister.email)) {
      toast.success("Please enter valid email ");
      checkErrorFlag = true
      toast.error("Please enter valid email ");
    }
    else if (userRegister.telegramId.length <= 5) {
      checkErrorFlag = true
      toast.error("Please select unique telegram Id");
    }
    else if (userRegister.twitter.length <= 0) {
      checkErrorFlag = true
      toast.error("Please select unique Twitter Id");
    }
    else if (userRegister.projectOrAdvising.length <= 0) {
      checkErrorFlag = true
      toast.error("Please select I’m a project / I’m advising a project");
    }
    if(dataShow.project === true) {
      if (userRegister.industry.length <= 0) {
        checkErrorFlag = true
        toast.error("Please select Industry");
      }
      else if (userRegister.blockChain.length <= 0) {
        checkErrorFlag = true
        toast.error("Please select Block Chain");
      }
      else if (userRegister.launchUrgency.length <= 0) {
        checkErrorFlag = true
        toast.error("Please select Launch Urgency");
      }
    }
    if(dataShow.advisingProject === true) {
      if (userRegister.interestedInPartner.length <= 0) {
        checkErrorFlag = true
        toast.error("Please Select any one option in interested in Partner");
      }
    }
    
    // else {
    //   error("Please enter valid email ");
    // }

    console.log(userRegister, file)
    var formData = new FormData();
    formData.append("name", userRegister.name);
    formData.append("email", userRegister.email);
    formData.append("telegramId", userRegister.telegramId);
    formData.append("twitter", userRegister.twitter);
    formData.append("projectOrAdvising", userRegister.projectOrAdvising);

    if (userRegister.projectOrAdvising === 'advising') {
      formData.append("interestedInPartner", userRegister.interestedInPartner || "");
      formData.append("chainSupport", userRegister.chainSupport);
      formData.append("topProjects", userRegister.topProjects);
    } else {
      formData.append("industry", userRegister.industry);
      formData.append("blockChain", userRegister.blockChain);
      formData.append("launchUrgency", userRegister.launchUrgency);
      formData.append("otherNotes", userRegister.otherNotes);
      formData.append("tentativeLaunchDate", userRegister.tentativeLaunchDate);
      if (file) {
        formData.append("file", file);
      }
    }

    if (!checkErrorFlag) {
      axios
        .post(addUserApiUrl, formData, file, { headers: { 'content-type': 'multipart/form-data' } })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Contact saved successfully");
          }
        })
        .catch(function (error) {
          toast.error("Invalid Form details", error);
        });
    }
  };

  return (
    <>
      <section className="contact-page-section">
        <Container>
          <Row className="row">
            <div className="column col-lg-5 col-md-5 col-sm-12">
              <div className="text-part">
                <div className="border-font-content">
                  <h1>Contact</h1>
                </div>
                <h5 className="mb-5">Welcome to Genesis Shards. Genesis Shards (GS) is a 25-member strong mission-driven global team that has built and scaled products at Harmony $ONE, Marlin Protocol $POND, Accenture, Deloitte, and Wharton.</h5>
                <div className='partner-title'>CHAIN PARTNERS</div>
                <div className="chain-company">
                  <div className="chain-logo">
                    <img src={ChainPartnerImg1} className="img-fluid" alt="" />
                  </div>
                  <div className="chain-logo">
                    <img src={ChainPartnerImg2} className="img-fluid" alt="" />
                  </div>
                </div>
                <div className='partner-title'>INVESTOR PARTNERS</div>
                <div className="">
                  <img src={InvestPartnerImg} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div className="column col-lg-7 col-md-7 col-sm-12">
              <div className='contact-form'>
                <form action="" onSubmit={handleSubmit}>
                  <Row>
                    <div className='col-3'>
                      <label>Name </label>
                    </div>
                    <div className='col-9'>
                      <input type="text" name='name' className='contact-fields' onChange={handleChange} />
                    </div>
                  </Row>
                  <Row>
                    <div className='col-3'>
                      <label>Email </label>
                    </div>
                    <div className='col-9'>
                      <input type="email" name='email' className='contact-fields' onChange={handleChange} />
                    </div>
                  </Row>
                  <Row>
                    <div className='col-3'>
                      <label>Telegram ID</label>
                    </div>
                    <div className='col-9'>
                      <input type="text" name='telegramId' className='contact-fields' onChange={handleChange} />
                    </div>
                  </Row>
                  <Row>
                    <div className='col-3'>
                      <label>Twitter</label>
                    </div>
                    <div className='col-9'>
                      <input type="text" name='twitter' className='contact-fields' onChange={handleChange} />
                    </div>
                  </Row>
                  <Row>
                    <div className='col-12'>
                      <div className="form-check-inline">
                        <label className="form-check-label">Are you a project OR helping / advising a project(s)?</label>
                      </div>
                      <div className='form-check-inline'>
                        <div className="form-check form-check-inline" onClick={() => { setDataShow({ project: true, advisingProject: false }) }}>
                          <input className="form-check-input" type="radio" name="projectOrAdvising" id="improject" onChange={handleChange} value="project" />
                          <label className="form-check-label" htmlFor="improject">I’m a project</label>
                        </div>
                        <div className="form-check form-check-inline" onClick={() => { setDataShow({ project: false, advisingProject: true }) }}>
                          <input className="form-check-input" type="radio" name="projectOrAdvising" id="improjectadvisor" onChange={handleChange} value="advising" />
                          <label className="form-check-label" htmlFor="improjectadvisor">I’m advising a project</label>
                        </div>
                      </div>
                    </div>
                  </Row>
                  {dataShow.project === true ? <>
                    <Row>
                      <div className='col-6'>
                        <label htmlFor="" className='select-label'>Industry</label>
                        <select aria-label="Default select contact-select contact-fields" name='industry' onChange={handleChange}>
                          <option aria-readonly>Please select</option>
                          <option value="Gaming">Gaming</option>
                          <option value="DeFi">DeFi</option>
                          <option value="Metaverse">Metaverse</option>
                          <option value="DAO">DAO</option>
                          <option value="Ecommerce">Ecommerce</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div className='col-6'>
                        <label htmlFor="" className='select-label'>Blockchain</label>
                        <select aria-label="Default select contact-select contact-fields" name='blockChain' onChange={handleChange}>
                          <option>Please select</option>
                          <option value="Ethereum">Ethereum</option>
                          <option value="Polygon">Polygon</option>
                          <option value="BNB Chain">BNB Chain</option>
                          <option value="Avalanche">Avalanche</option>
                          <option value="Harmony">Harmony</option>
                          <option value="Hedera">Hedera</option>
                          <option value="Undecided">Undecided</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </Row>
                    <Row>
                      <div className='col-6'>
                        <label htmlFor="" className='select-label'>Launch Urgency</label>
                        <select aria-label="Default select contact-select contact-fields" name='launchUrgency' onChange={handleChange}>
                          <option>Please select</option>
                          <option value="immediate">immediate</option>
                          <option value="1-3 months">1-3 months</option>
                          <option value="3-6 months">3-6 months</option>
                        </select>
                      </div>
                      <div className='col-6'>
                        <label htmlFor="" className='select-label'>Tentative Launch Date</label>
                        <input type="date" className='react-date-picker contact-fields' name='tentativeLaunchDate' onChange={handleChange} />
                      </div>
                    </Row>
                    <Row>
                      <div className='col-3'>
                        <label>Other Notes</label>
                      </div>
                      <div className='col-9'>
                        <input type="text" onChange={handleChange} className="contact-fields" name='otherNotes' />
                      </div>
                    </Row>
                    <Row className='justify-content-center'>
                      <div className="file-upload">
                        <Row className='justify-content-center'>
                          <div className='col-3'>
                            <label>Upload a file</label>
                          </div>
                          <div className='col-9'>
                          <input type="file" className='contact-fields' onChange={handleChange} />
                          </div>
                        </Row>
                      </div>
                    </Row>
                  </> : ""
                  }

                  {dataShow.advisingProject === true ?
                    <>
                      <Row>
                        <div className='col-4'>
                          <label>Mention the chains you support</label>
                        </div>
                        <div className='col-8'>
                          <input type="text" onChange={handleChange} className="contact-fields" name='chainSupport' />
                        </div>
                      </Row>
                      <Row>
                        <div className='col-4'>
                          <label>Top 7 Projects backed so far</label>
                        </div>
                        <div className='col-8'>
                          <input type="text" onChange={handleChange} className="contact-fields" name='topProjects' />
                        </div>
                      </Row>
                      <Row>
                        <div className='col-12'>
                          <div className="form-check-inline mb-3">
                            <label className="form-check-label">Are you a project OR helping / advising a project(s)?</label>
                          </div>
                        </div>
                        <div className='col-12 d-lg-flex d-block'>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" onChange={handleChange} name="interestedInPartner" id="yeslable" value="yes" />
                            <label className="form-check-label" htmlFor="yeslable">Yes</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" onChange={handleChange} name="interestedInPartner" id="nolable" value="no" />
                            <label className="form-check-label" htmlFor="nolable">No</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" onChange={handleChange} name="interestedInPartner" id="maybelable" value="maybe" />
                            <label className="form-check-label" htmlFor="maybelable">Maybe</label>
                          </div>
                        </div>
                      </Row>
                    </> : ""
                  }
                  <Row>
                    <div className="col-12 text-center schedule-call">
                      <Link to="">Schedule a call on Calendly to know more</Link>
                    </div>
                  </Row>

                  <div className="submit-btn">
                    <button type='submit'> Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </Row>
        </Container>
      </section>

      <section className="why-moxie-section">
        <Container fluid>
          <div className="section-heading">
            <div className="why-moxie-heading">WHY GENESIS SHARDS</div>
          </div>
          <Row>
            <div className='col-lg-4 col-md-4 col-sm-12'>
              <div className="why-moxie-card position-relative">
                <h2 className="mb-lg-4 mb-md-4 mb-3">Simple UI/UX</h2>
                <p className='why-moxie-card-p'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et netus et malesuada fames ac turpis egestas. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in.</p>
                <img src={UiuxIcon} alt="" className="img-fluid easy-implementations" />
              </div>
              <div className="d-flex text-center cards">
                <div className="why-moxie-card small mx-2 w-50">
                  <img src={TimeIcon} className="img-fluid" alt="" />
                  <h2>Save Time</h2>
                </div>
                <div className="why-moxie-card small why-moxie-card-bg mx-2 w-50">
                  <h2 className="mb-lg-4 mb-md-0 mb-2">Super Fast</h2>
                  <span className="font-big">50x</span>
                </div>
              </div>
            </div>
            <div className='col-lg-8 col-md-8 col-sm-12'>
              <div className="why-moxie-card position-relative">
                <h2 className="mb-3 mb-lg-4 mb-md-4">Hard Problems. Easy Implementations</h2>
                <p className='why-moxie-card-p mb-3'>Nec nam aliquam sem et tortor consequat. Fusce id velit ut tortor pretium viverra suspendisse potenti. Faucibus pulvinar elementum integer enim. Morbi blandit cursus risus at ultrices mi tempus imperdiet nulla. Convallis a cras semper auctor neque vitae tempus. Quam elementum pulvinar etiam non. Rhoncus dolor purus non enim praesent elementum facilisis leo.</p>
                <img src={EsyImplinemtetion} alt="" className="img-fluid uiux-icon" />
              </div>
              <div className="why-moxie-card why-moxie-card-bg">
                <h2 className="mb-3 mb-lg-4 mb-md-4">Nisi porta lorem mollis aliquam</h2>
                <p className="mb-3">Scelerisque in dictum non consectetur a erat nam at. Lorem ipsum dolor sit amet consectetur adipiscing elit ut aliquam. Ullamcorper eget nulla facilisi etiam dignissim diam quis. Arcu vitae elementum curabitur vitae nunc sed.</p>
                <p className="mb-3">Pharetra sit amet aliquam id diam maecenas ultricies mi. Mauris in aliquam sem fringilla ut morbi. Pellentesque nec nam aliquam sem et tortor consequat id porta. Augue eget arcu dictum varius duis at. Et netus et malesuada fames ac. Amet purus gravida quis blandit turpis cursus in hac.</p>
                <p>In mollis nunc sed id semper risus in hendrerit. Sit amet consectetur adipiscing elit pellentesque habitant morbi. Aliquet porttitor lacus luctus accumsan.</p>
                {/* <p>Nec nam aliquam sem et tortor consequat. Fusce id velit ut tortor pretium viverra suspendisse potenti. Faucibus pulvinar elementum integer enim. Morbi blandit cursus risus at ultrices mi tempus imperdiet nulla. Convallis a cras semper auctor neque vitae tempus. Quam elementum pulvinar etiam non. Rhoncus dolor purus non enim praesent elementum facilisis leo.</p> */}
              </div>
            </div>
          </Row>
        </Container>
      </section>

    </>
  );
};
