import React from 'react';
import { Link } from 'react-router-dom';
import {routes} from "./constants";
import { HashLink } from 'react-router-hash-link';

export const NavigationItems = ({mobile = false}) => {
  return (
    <ul>
      {
        routes.map((route, id) => (
            <li key={`menu-${id}`}><HashLink to={route.to}>{route.label}</HashLink></li>
          )
          )}
        <li><Link to={"/contact"}>CONTACT</Link></li>
      {mobile && (
        <li key={`menu-${routes.length + 1}`}><a href="https://app.genshards.com" target="_blank">GO TO APP</a></li>
      )}
    </ul>
  );
};