import React from 'react';
import TopBar from "../TopBar";
import Container from "../Container";
import Row from "../Row";
import Logo from "../../components/Logo";
import Navigation from "../../components/Navigation";
import SignInButton from "../../components/SignInButton";

export const Header = () => {
  return (
    <header className="site-header">
      {/* <TopBar/> */}
      <div className="site-header__inner">
        <Container>
          <Row>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <Logo src={"/img/logotype.svg"} alt={"Genesis"}/>
              {/* <Navigation/> */}
              {/* <SignInButton/> */}
            </div>
          </Row>
        </Container>
        <Container>
          <Navigation isMobile={true}/>
        </Container>
      </div>
    </header>
  )
};